<template>
  <nav
    v-if="content.menu?.[0]"
    ref="facilityBar"
    role="navigation"
    aria-label="Facility menu"
    class="facility-bar"
  >
    <SimpleButton
      v-if="!isDesktop"
      class="open-button hidden-tablet"
      :title="useMicroCopy('facilitybar.expandbutton.label')"
      :aria-expanded="isExpanded"
      aria-controls="facility-bar-panel"
      solid
      @click="openModal"
    >
      {{ useMicroCopy("facilitybar.expandbutton.text") }}
    </SimpleButton>

    <component
      :is="isDesktop ? 'div' : GenericModal"
      ref="modal"
      height-auto
      @close="onCloseModal"
    >
      <div :class="['facility-bar-menu', 'parent-bg', { expanded: isExpanded }]">
        <p v-if="content.commercialName" class="bold name">
          {{ content.commercialName }}
        </p>
        <ul id="facility-bar-panel" class="facility-bar-list">
          <li
            v-if="formattedPhoneNumber?.length"
            class="facility-bar-list-item"
          >
            <SimpleButton
              :href="`tel:${formattedPhoneNumber}`"
              class="phone-button"
              @click="
                tracking({
                  event: 'click.action',
                  click: 'call',
                  click_chapter1: 'sticky_hero',
                  click_chapter2: 'CTA',
                })
              "
            >
              <SvgIcon name="func_call" class="icon" />
              <span>
                <p class="bold">
                  {{
                    callCenterPhoneNumber?.length
                      ? callCenterPhoneNumber
                      : content.phoneNumber
                  }}
                </p>
                <p v-if="content.menu[0].phone_schedules" class="baseline">
                  {{ content.menu[0].phone_schedules }}
                </p>
              </span>
            </SimpleButton>
          </li>
          <li
            v-if="!isPublicGestionType && content.menu[0].can_callback"
            class="facility-bar-list-item"
          >
            <SimpleButton
              @click="
                openCallbackModal();
                tracking({
                  event: 'click.action',
                  click: useSnakeCaseFilter(
                    useMicroCopy('facilitybar.callbackcta.label')
                  ),
                  click_chapter1: 'sticky_hero',
                  click_chapter2: 'CTA',
                });
              "
            >
              {{ useMicroCopy("facilitybar.callbackcta.label") }}
            </SimpleButton>
          </li>
          <li
            v-if="!isPublicGestionType && content.menu[0].can_visit"
            class="facility-bar-list-item"
          >
            <SimpleButton
              :solid="true"
              @click="
                openVisitModal();
                tracking({
                  event: 'click.action',
                  click: useSnakeCaseFilter(
                    useMicroCopy('facilitybar.visitcta.label')
                  ),
                  click_chapter1: 'sticky_hero',
                  click_chapter2: 'CTA',
                });
              "
            >
              {{ useMicroCopy("facilitybar.visitcta.label") }}
            </SimpleButton>
          </li>
          <li v-if="content.menu[0].cta_label_1 && content.menu[0].cta_link_1?.story?.url" class="facility-bar-list-item">
            <SimpleButton
              @click="
                openCustomForm1Modal();
                tracking({
                  event: 'click.action',
                  click: useSnakeCaseFilter(content.menu[0].cta_label_1),
                  click_chapter1: 'sticky_hero',
                  click_chapter2: 'CTA',
                });
              "
            >
              {{ content.menu[0].cta_label_1 }}
            </SimpleButton>
          </li>
          <li v-if="content.menu[0].cta_label_2 && content.menu[0].cta_link_2?.story?.url" class="facility-bar-list-item"> 
            <SimpleButton
              :solid="true"
              @click="
                openCustomForm2Modal();
                tracking({
                  event: 'click.action',
                  click: useSnakeCaseFilter(content.menu[0].cta_label_2),
                  click_chapter1: 'sticky_hero',
                  click_chapter2: 'CTA',
                });
              "
            >
              {{ content.menu[0].cta_label_2 }}
            </SimpleButton>
          </li>
        </ul>       
      </div>
    </component>
  </nav>
</template>

<script setup>
import GenericModal from "@/components/GenericModal.vue";

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
});
const trackingStore = useTrackingStore();
const { content } = toRefs(props);
const isDesktop = inject("isDesktop");
const isExpanded = ref(false);
const facilityBar = ref(null);

// this variable is supposed to depend on content.value.gestionType
// but the field is not currently present in SB so always false
const isPublicGestionType = false;

let facilityTypeGroupKey;
let callCenterPhoneNumber;
const query = gql`
  query {
    searchByFacilityUniqueKey(facilityUniqueKey: "${content.value.facilityUniqueKey}") {
      facilityTypeGroupKey
    }
  }
  `;
const { data, loading } = await useAsyncQuery(query);
if (typeof data !== "undefined") {
  facilityTypeGroupKey =
    data?.value?.searchByFacilityUniqueKey?.facilityTypeGroupKey;
  callCenterPhoneNumber = usePhoneByFacityType(facilityTypeGroupKey);
}

let formattedPhoneNumber = "";
if (
  typeof useMicroCopy("phone.prefix") === "string" &&
  typeof callCenterPhoneNumber === "string"
) {
  formattedPhoneNumber = useFormattedPhone(
    useMicroCopy("phone.prefix"),
    callCenterPhoneNumber
  );
} else if (
  typeof content.value.prefix === "string" &&
  typeof content.value.phoneNumber === "string"
) {
  const prefix = content.value.prefix && content.value.prefix !== ""  ? content.value.prefix : useMicroCopy("phone.prefix")
  formattedPhoneNumber = useFormattedPhone(
    prefix,
    content.value.phoneNumber
  );
}


const modal = ref(null);

const openModal = () => {
  isExpanded.value = true;
  modal && modal.value.openModal();
};
const onCloseModal = () => {
  isExpanded.value = false;
};

useResizeObserver(facilityBar, () => {
  if (isDesktop.value === true) {
    isExpanded.value = false;
  }
});

const openCallbackModal = inject("callback", () => {});
const openVisitModal = inject("facility-visit", () => {});
const openCustomForm1Modal = inject("custom-form-1", () => {});
const openCustomForm2Modal = inject("custom-form-2", () => {});

const tracking = (data) => {
  trackingStore.sendTrackingData(data);
};
</script>

<style lang="scss" scoped>
.facility-bar {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  z-index: $z-index-fixed;
  margin: 0.25rem auto;
  width: 95.5%;
  max-width: $header-width;

  @include for-tablet-landscape-up {
    position: sticky;
    top: 5.25rem;
    bottom: auto;
    left: 0;
    transform: none;
    margin: 1.25rem auto;
  }

  .open-button {
    width: 100%;
  }
}

.facility-bar-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include pair-7;
  border-radius: $radius-xs;
  border: $border-grey;
  padding: 0 2.025%;
  min-height: 3rem;
  text-align: center;
  width: 100%;

  @include for-tablet-landscape-up {
    padding-top: 0.63rem;
    padding-bottom: 0.63rem;
    min-height: 4rem;
    position: sticky;
    top: 1.25rem;
    bottom: auto;
    left: 0;
    transform: none;
    text-align: start;
  }

  &.expanded {
    margin-bottom: 0;
    padding: 0;
    flex-wrap: wrap;
    flex-direction: column;
    border: 0;

    .facility-bar-list {
      margin-top: 2rem;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    .facility-bar-list-item {
      margin-left: 0;
      width: 100%;
      justify-content: center;

      &:not(:first-child) {
        margin-top: 0.625rem;
      }
    }

    .simple-button {
      width: 100%;
    }
  }

  .name {
    max-width: calc(100% - 32px);
    @include size-l;

    @include for-tablet-landscape-up {
      @include size-s;
      max-width: none;
    }
  }

  .facility-bar-list {
    display: flex;
    z-index: 1;
  }

  .facility-bar-list-item {
    display: flex;
    align-items: center;
    margin-left: 0.62rem;

    .simple-button .icon {
      width: 2rem;
      height: 2rem;
      flex: none;
    }
  }

  :deep(.simple-button) {
    &.phone-button {
      padding-left: 0;
      padding-right: 0;
      font-size: 1.125rem;
      line-height: 120%;

      &::before {
        background: none;
      }
    }
  }
  .baseline {
    font-size: 0.625rem;
    line-height: 140%;
    color: var(--text-70);
  }
}
</style>
